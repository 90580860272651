body {
  font-family: IBM-Regular;
  color: #667080;
}

.management-layout {
  .ant-layout-header {
    box-shadow: 0px 4px 28px -10px rgb(0 0 0 / 15%);
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    button {
      border: none;
      box-shadow: none;
    }
  }
  aside {
    background-color: #ffffff;
  }
  .management-content {
    min-height: calc(100vh - 64px);
    padding: 16px 24px;
  }
}

.top-left-items {
  display: flex;
  gap: 16px;

  .store-info {
    display: flex;
    flex-direction: column;
  }
}

.top-right-items {
  display: flex;
}

.title {
  font-family: "IBM-Semibold";
  font-size: 20px;
  color: #0c0e11;
  line-height: normal;
}

.sub-title {
  font-family: "IBM-Semibold";
  font-size: 16px;
  color: #5c6372;
  line-height: normal;

  .content {
    padding-left: 8px;
    font-family: "IBM-Regular";
  }
}

.ant-layout-header {
  height: 100px;
}

.step-custom {
  .ant-steps-item-process .ant-steps-item-icon {
    background-color: #e50019;
    border-color: #e50019;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #e50019;
    .ant-steps-icon {
      color: #e50019;
    }
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail:after {
    background-color: #e50019;
  }

  .ant-steps-item-title {
    font-size: 14px;
  }
}

.wrap-content {
  padding: 16px;
  min-height: calc(100vh - 64px);
  background-color: #ffffff;
}
.content-tracking {
  max-width: 1024px;
  width: 100%;
  margin: 8px auto;
  .ant-tabs {
    color: #667080;
  }
  .ant-tabs-nav {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

.section-element {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 24px;
  }
  h3 {
    font-size: 14px;
    color: #667080;
    font-family: "IBM-Medium";
    margin-bottom: 0;
    margin-top: 0;
  }
  p {
    margin-bottom: 0;
    font-family: IBM-Light;
    span {
      font-family: IBM-Light;
      font-style: italic;
    }
  }
  &.section-status {
    align-items: flex-start;

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      span {
        &.wating {
          color: #000000;
        }
        &.processing {
          color: #d4a62e;
        }
        &.complete {
          color: #22bd4d;
        }
      }
    }
  }
}

.section-tracking {
  .wrap-google-map {
    width: 100%;
    height: 500px;
  }
  h3 {
    align-self: flex-start;
  }
  p {
    margin-top: 20px;
  }
}

.section-element.section-detail {
  margin-bottom: 22px;
}

.section-detail {
  .right {
    a {
      display: flex;
      flex-direction: column;
      color: #00aa61;
      align-items: center;
    }
  }
}

.section-eta {
  display: flex;
  align-items: center;

  p {
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.main-section-saperate {
  padding: 16px 24px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;

  .section-transit-detail {
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 24px;
    &:last-child {
      border-bottom: none;
      padding-bottom: 15px;
    }
  }
}

.shipping-title {
  font-family: "IBM-Semibold";
  font-size: 14px;
  margin: 0;
  color: #0c0e11;
}

.summary-box {
  display: flex;
  margin-top: 16px;
  padding: 16px;
  background-color: #f6f7fb;
  color: black;
  border-radius: 8px;

  .step-icon {
    margin-top: 16px;

    @media (max-width: 450px) {
      max-width: 250px;
    }

    .ant-steps-item-finish {
      .ant-steps-item-tail::after {
        background-color: #4765f2 !important;
      }
    }

    .ant-steps-item-tail::after {
      background-color: #bec4d1 !important;
      height: 3px !important;
    }

    .ant-steps-item-content {
      margin-top: 8px !important;
    }

    .ant-steps-icon {
      display: flex;
      align-items: stretch;
      justify-content: center;
    }
  }

  .ant-steps-item-title {
    @media (min-width: 599px) {
      font-size: 12px;
    }

    @media (max-width: 599px) {
      font-size: 10px;
    }
  }

  .ant-steps-item-description {
    @media (min-width: 599px) {
      font-size: 12px;
    }

    @media (max-width: 599px) {
      font-size: 10px;
    }
  }

  .section-eta-time {
    display: flex;
    margin-top: 8px;

    .delay-eta-text {
      color: #f9a60c;
      padding-left: 8px;

      @media (min-width: 599px) {
        font-size: 14px;
      }

      @media (max-width: 599px) {
        font-size: 10px;
      }
    }
  }
}

.info-text {
  display: flex;

  .text-title {
    color: black;

    @media (min-width: 599px) {
      font-size: 14px;
    }

    @media (max-width: 599px) {
      font-size: 10px;
    }
  }

  .text-content {
    color: #5c6372;
    padding-left: 8px;

    @media (min-width: 599px) {
      font-size: 14px;
    }

    @media (max-width: 599px) {
      font-size: 10px;
    }
  }
}

@font-face {
  font-family: IBM-Medium;
  src: url("../assets/fonts/IBM_Plex_Sans_Thai/IBMPlexSansThai-Medium.ttf");
}

@font-face {
  font-family: IBM-Semibold;
  src: url("../assets/fonts/IBM_Plex_Sans_Thai/IBMPlexSansThai-SemiBold.ttf");
}

@font-face {
  font-family: IBM-Bold;
  src: url("../assets/fonts/IBM_Plex_Sans_Thai/IBMPlexSansThai-Bold.ttf");
}

@font-face {
  font-family: IBM-Regular;
  src: url("../assets/fonts/IBM_Plex_Sans_Thai/IBMPlexSansThai-Regular.ttf");
}

@font-face {
  font-family: IBM-Light;
  src: url("../assets/fonts/IBM_Plex_Sans_Thai/IBMPlexSansThai-Light.ttf");
}

@font-face {
  font-family: IBM-ExtraLight;
  src: url("../assets/fonts/IBM_Plex_Sans_Thai/IBMPlexSansThai-ExtraLight.ttf");
}
